<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <v-card class="" loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text>
          <div class="d-flex align-center mb-2">
            <div class="mr-2" v-if="botdetails._id">
              <v-chip v-if="botdetails.stage === 3" small label color="success">Closed</v-chip>
              <v-chip v-else-if="botdetails.stage === 1" small label color="warning">Pending for review</v-chip>
              <v-chip v-else-if="botdetails.stage === 0" small label color="info">Open</v-chip>
            </div>
            <v-btn class="shadow-off" small icon @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
            <v-spacer></v-spacer>
            <div v-if="botdetails.stage === 0 && $nova.hasRight(right, 'review')" class="info--text">
              Waiting for preparer to finish the analysis.
            </div>

          </div>
          <div class="d-flex align-center mb-2" v-if="botdetails._id">
            <div>
              <div class="d-flex align-center">
                <div class="subtitle-1 font-weight-bold mr-2">{{ botdetails.botname }}</div>
              </div>
              <div>
                <span class="mr-2"><v-icon class="mr-1">mdi-calendar-range</v-icon> Period: </span>
                <span class="mr-1">{{ $nova.formatDate(botdetails.from) }}</span>
                <span class="mr-1">- {{ $nova.formatDate(botdetails.to) }}</span>
              </div>
            </div>
            <v-spacer></v-spacer>
          </div>
          <div v-if="loading && (!botdetails._id || !botdetails._id)">
            <v-skeleton-loader type="table-heading, list-item-two-line, article, article"></v-skeleton-loader>
          </div>
          <div class="py-8" v-else-if="!botdetails._id">
            <div class="body-1 mb-4">
              No Bot details found
            </div>
          </div>
          <div v-else-if="botdetails.stage === 3 && Object.keys(rundetails).length === 0" class="pa-8 title text-center grey--text">
            Bot not run
          </div>
          <div v-else>
            <div v-if="botdetails.stage !== 3">
              <v-alert v-if="!savedParameters && hasParameters" type="info" text dense border="left">Save the Input parameters to
                proceed</v-alert>
              <v-alert v-else-if="!savedFiles && hasFiles" type="info" text dense border="left">Upload all the required files to start
                the analysis</v-alert>
            </div>
            <div v-if="(processedFiles || !hasFiles) && (savedParameters || !hasParameters) && botdetails.stage !== 3" class="d-flex">
              <v-spacer></v-spacer>
              <v-btn color="primary" v-if="Object.keys(rundetails).length === 0 && botdetails.stage === 0" class="ml-2 shadow-off" small @click="startRun()">
                <v-icon class="mr-1">mdi-play</v-icon>
                Start Run
              </v-btn>
              <v-btn color="primary" v-else-if="botdetails.stage === 0" class="ml-2 shadow-off" small @click="startRun()">
                <v-icon class="mr-1">mdi-play</v-icon>
                Re Run
              </v-btn>
              <v-btn
                color="error"
                class="ml-2 shadow-off"
                v-if="botdetails.stage === 1 && config.review && $nova.hasRight(right, 'review')"
                small
                @click="open()"
              >
                <v-icon class="mr-1">mdi-arrow-left</v-icon>
                Send back to preparer
              </v-btn>
              <v-btn
                color="success"
                class="ml-2 shadow-off"
                v-if="botdetails.stage === 1 && config.review && $nova.hasRight(right, 'review')"
                small
                @click="close()"
              >
                <v-icon class="mr-1">mdi-lock</v-icon>
                Review & Close
              </v-btn>
              <v-btn
                color="info"
                class="ml-2 shadow-off"
                v-if="botdetails.stage === 2 && config.review && $nova.hasRight(right, 'review')"
                small
                @click="open()"
              >
                <v-icon class="mr-1">mdi-lock-open-variant</v-icon>
                Re-open Analysis
              </v-btn>
              <v-btn
                color="warning"
                class="ml-2 shadow-off"
                v-if="botdetails.stage === 0 && config.review && $nova.hasRight(right, ['add', 'edit'])"
                small
                @click="sendReview()"
              >
                <v-icon class="mr-1">mdi-check</v-icon>
                Send for Review
              </v-btn>

            </div>
            <v-tabs v-model="tab" bg-color="primary" height="30">
              <v-tab :key="0" v-if="!config.showverifications" class="text-transform-none justify-start"><v-icon class="mr-2">mdi-robot-happy-outline</v-icon>Verifications</v-tab>
              <v-tab :key="1" v-if="hasParameters" class="text-transform-none justify-start"><v-icon
                  class="mr-2">mdi-play-box-multiple-outline</v-icon>Input Parameters</v-tab>
              <v-tab :key="2" v-if="savedParameters || (!hasParameters && hasFiles)" class="text-transform-none justify-start"><v-icon
                  class="mr-2">mdi-cloud-upload-outline</v-icon>Files</v-tab>
              <v-tab :key="3" class="text-transform-none justify-start"
                v-if="(savedParameters || !hasParameters) && Object.keys(rundetails).length > 0"><v-icon
                  class="mr-2">mdi-format-list-bulleted</v-icon>Result</v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <v-tabs-items v-model="tab">
              <v-tab-item :key="0" v-if="!config.showverifications">
                <div class="mt-4">
                  <div v-for="(item, i) in verificationlist" :key="i">
                    <div class="d-flex py-1 px-4 hoverable align-center">
                      <div class="font-weight-bold mr-4">{{ i + 1 }}.</div>
                      <div class="mr-2">
                        <v-tooltip
                          bottom
                          content-class="tooltip-bottom"
                          v-if="(rundetails[item._id] || {}).stage === 1 && (rundetails[item._id] || {}).issue === 0"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="grey"
                              >mdi-check-circle</v-icon
                            >
                          </template>
                          <span>Completed</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom"
                          v-else-if="(rundetails[item._id] || {}).stage === 1 && (rundetails[item._id] || {}).issue === 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                          </template>
                          <span>Completed & Observation found</span>
                        </v-tooltip>
                        <v-tooltip
                          bottom
                          content-class="tooltip-bottom"
                          v-else-if="(rundetails[item._id] || {}).stage === 4"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="error"
                              >mdi-radiobox-marked</v-icon
                            >
                          </template>
                          <span>Currently running</span>
                        </v-tooltip>
                        <v-tooltip
                          bottom
                          content-class="tooltip-bottom"
                          v-else-if="(rundetails[item._id] || {}).stage === 2"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="error"
                              >mdi-close-circle</v-icon
                            >
                          </template>
                          <span>Error: {{(rundetails[item._id] || {}).message || "Unknown Error"}}</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom" v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="info"
                              >mdi-timer-sand</v-icon
                            >
                          </template>
                          <span>Pending</span>
                        </v-tooltip>
                      </div>
                      <div class="">
                        <span class="mr-1 font-weight-bold">{{ item.code }}</span>
                        <v-icon>mdi-circle-small</v-icon>
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item :key="1" v-if="hasParameters">
                <InputParamerter class="mt-4" :parameter="setupList.parameter" :parametervalue="inputparametersvalues" :editable="botdetails.stage !== 3"
                  :userType="false" :apiParam="'/v2/bot/analysis/storeinputparameter/' + botdetails._id"
                  @startloading="loading = true" @stoploading="loading = false" @reload="refreshData()" />
              </v-tab-item>
              <v-tab-item :key="2">
                <FileUploads :files="setupList.file" :filevalues="uploaddetails" :editable="botdetails.stage === 0"
                  :storepath="'/v2/bot/analysis/storefile/' + botdetails._id" :fileproerr="fileprocesserrors"
                  :fileprostage="fileprocessstage" :fileuppercent="fileuploadpercent" :filesdataPros="filesdata"
                  :fileconfig="fileConfigInfo" @startloading="loading = true" @stoploading="loading = false"
                  @reload="refreshData()" />
              </v-tab-item>
              <v-tab-item :key="3" v-if="Object.keys(rundetails).length > 0">
                <div>
                  <div class="d-flex my-2">
                    <div style="min-width: 170px; max-width: 170px">
                      <v-btn color="primary" class="mb-4 shadow-off" small @click.stop="downloadResult()">
                        <v-icon class="mr-1">mdi-file-excel</v-icon>
                        Download Excel
                      </v-btn>                      
                      <div v-if="Object.keys(rundetails).length > 1">
                        <template v-for="(v, k) in verificationlist">
                          <div :key="k" :class="`${selectedresultannexure === k
                            ? 'border-left-primary'
                            : 'border-left-transparent'
                            }`">
                            <div @click.stop="selectedresultannexure = k; generateAnnexurelist(k);"
                              :class="`px-2 py-1 d-flex align-start cursor-pointer hoverable ${selectedresultannexure === k ? 'grey lighten-4' : ''}`">
                              <div class="mr-2">
                                <v-tooltip bottom content-class="tooltip-bottom"
                                  v-if="(rundetails[v._id] || {}).stage === 1 && (rundetails[v._id] || {}).issue === 0">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="grey">mdi-check-circle</v-icon>
                                  </template>
                                  <span>Completed</span>
                                </v-tooltip>
                                <v-tooltip bottom content-class="tooltip-bottom"
                                  v-else-if="(rundetails[v._id] || {}).stage === 1 && (rundetails[v._id] || {}).issue === 1">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                                  </template>
                                  <span>Completed & Observation found</span>
                                </v-tooltip>
                                <v-tooltip bottom content-class="tooltip-bottom" v-else-if="
                                  (rundetails[v._id] || {}).stage === 4
                                ">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="error">mdi-radiobox-marked</v-icon>
                                  </template>
                                  <span>Currently running</span>
                                </v-tooltip>
                                <v-tooltip bottom content-class="tooltip-bottom" v-else-if="
                                  (rundetails[v._id] || {}).stage === 2
                                ">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="error">mdi-close-circle</v-icon>
                                  </template>
                                  <span>Error: {{(rundetails[v._id] || {}).message || "Unknown Error"}}</span>
                                </v-tooltip>
                                <v-tooltip bottom content-class="tooltip-bottom" v-else>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                                  </template>
                                  <span>Pending</span>
                                </v-tooltip>
                              </div>
                              <div class="line-1-2 py-1">
                                <span class="mr-1 font-weight-bold">{{ v.code }}</span>
                                <br/>
                                {{ v.name }}
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                      <div v-else>
                        <template v-for="(v, k) in annexurelist">
                          <div :key="k" :class="`${selectedannexure === v.value ? 'border-left-primary': 'border-left-transparent'}`">
                            <div @click="selectedannexure = v.value; readAnnexure(v.value,(verificationlist[selectedresultannexure] || {})._id)"
                              :class="`px-2 py-1 d-flex align-start cursor-pointer hoverable ${selectedannexure === v.value ? 'grey lighten-4' : ''}`">
                              <div class="line-1-2">
                                {{ v.name }}
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="flex-grow-1 px-4" style="min-width: 0">
                      <v-tabs v-model="resulttab" bg-color="primary" height="30">
                        <v-tab :key="0" v-if="((((rundetails[(verificationlist[selectedresultannexure] || {})._id || ''] || {}).summary || [])[0] || {}).data || []).length > 0" class="text-transform-none justify-start"><v-icon class="mr-2">mdi-chart-box</v-icon>Summary</v-tab>
                        <v-tab :key="1" @click="generateAnnexurelist(selectedresultannexure)" class="text-transform-none justify-start"><v-icon class="mr-2">mdi-format-list-text</v-icon>Annexure</v-tab>
                      </v-tabs>
                      <v-divider></v-divider>
                      <v-tabs-items v-model="resulttab">
                        <v-tab-item :key="0" v-if="((((rundetails[(verificationlist[selectedresultannexure] || {})._id || ''] || {}).summary || [])[0] || {}).data || []).length > 0">
                          <div class="d-inline-block">
                            <v-simple-table dense class="mt-4" :fixed-header="true">
                              <template v-slot:default>
                                <thead>
                                  <tr class="grey lighten-3">
                                    <template v-for="(v, k) in (((rundetails[(verificationlist[selectedresultannexure] || {})._id || ''] || {}).summary || [])[0] || {}).data[0]">
                                      <th class="grey lighten-3" :key="k">
                                        {{ k }}
                                      </th>
                                    </template>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v, k) in (((rundetails[(verificationlist[selectedresultannexure] || {})._id || ''] || {}).summary || [])[0] || {}).data" :key="k">
                                    <template v-for="(vv, kk) in v">
                                      <td :key="kk" v-if="kk !== '_id'">
                                        {{
                                          typeof vv === "number"
                                          ? $nova.formatNumber(vv.toFixed(0))
                                          : vv
                                        }}
                                      </td>
                                    </template>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>
                        </v-tab-item>
                        <v-tab-item :key="1">
                          <div class="d-flex mt-4">
                            <lb-dropdown v-if="Object.keys(rundetails).length > 1" label="Annexures" v-model="selectedannexure" :items="annexurelist" @change="
                              readAnnexure(
                                selectedannexure,
                                (verificationlist[selectedresultannexure] || {})._id
                              )
                            " />
                            <v-spacer class="flex-grow-1"></v-spacer>
                            <!-- <v-btn icon><v-icon>mdi-download</v-icon></v-btn> -->
                          </div>
                          <div class="" v-if="currentannexuredata.length > 0">
                            <v-simple-table dense class="" :fixed-header="true">
                              <template v-slot:default>
                                <thead>
                                  <tr class="grey lighten-3">
                                    <template v-for="(v, k) in currentannexuredata[0]">
                                      <th class="grey lighten-3" :key="k" v-if="k !== '_id'">
                                        {{ (k || "").replaceAll("_", " ") }}
                                      </th>
                                    </template>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v, k) in currentannexuredata" :key="k">
                                    <template v-for="(vv, kk) in currentannexuredata[0]">
                                      <td :key="kk" v-if="kk !== '_id'">
                                        {{
                                          typeof v[kk] === "number"
                                          ? $nova.formatNumber(v[kk].toFixed(0))
                                          : v[kk]
                                        }}
                                      </td>
                                    </template>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>
                          <div v-else class="text-center grey--text title pa-6">
                            No data available
                          </div>
                        </v-tab-item>
                      </v-tabs-items>
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import InputParamerter from './InputParamerter.vue';
import FileUploads from './FileUploads.vue';
export default {
  name: 'bots_component',
  // props: ['props'],
  data: function () {
    return {
      right: "bot_project",
      project: "",
      loading: false,
      id: "",
      tab: 0,
      metadata: {},
      botproject: {},
      botdetails: {},
      setupList: {},
      verificationlist: [],
      hasParameters: false,
      savedParameters: false,
      inputparametersvalues: {},
      uploaddetails: {},
      fileuploaddata: {},
      fileprocesserrors: {},
      fileuploadpercent: {},
      fileprocessstage: {},
      filesdata: {},
      processedFiles: false,
      savedFiles: false,
      hasFiles: false,
      rundetails: {},
      annexurelist: [],
      selectedresultannexure: 0,
      resulttab: 0,
      selectedannexure: "",
      currentannexuredata: [],
      annexuredata: {},
      fileConfigInfo: {},
    }
  },
  props: {
    botid: {
      type: String,
      default: ""
    },
    config: {
      type: Object,
      default: function(){
        return {}
      },
    }
  },
  components: {
    InputParamerter, FileUploads
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.id = this.$route.params.id || "";
      this.userid = this.$store.state.user.id;
      this.currentannexuredata = [];
      this.annexuredata = {};
      this.getData();
    },
    getData() {
      // this.loading = true;
      // this.project = this.$route.params.id;

      // return this.axios.post("/v2/financialclose/analysis/getbotdetails/" + this.id).then(dt => {
      //   if (dt.data.status === "success" && dt.data.data.length > 0) {
      //     let data = dt.data.data[0] || {}
      //     this.metadata = data.metadata || {};
      //     this.userlist = [];
      //     this.userlist = this.botdetails.userlist || [];
      //     this.managerlist = [];
      //     this.managerlist = this.botdetails.managerlist || [];
      //     this.stage = this.botdetails.stage;
      //   }
      // }).catch(err => {
      //   this.$store.commit("sbError", err.message || err || "Unknown error!");
      // }).finally(() => {
      // this.loading = false;
      this.loadBot();
      // })
    },
    generateAnnexurelist: function () {
      let ndata = [];
      console.log(this.selectedresultannexure);
      let selectedverification = this.verificationlist[this.selectedresultannexure] || {};
      let rundata = this.rundetails[selectedverification._id] || {};
      // console.log(selectedverification, rundata);
      if (Object.keys(rundata).length > 0) {
        let annxdata = rundata;
        let hasannexure = false;
        console.log(annxdata);
        for (let i = 0; i < (annxdata.annexure || []).length; i++) {
          const el = (annxdata.annexure || [])[i];
          // console.log(el);
          ndata.push({ name: el.name, value: el.collection || el.name });
          if (el.collection === this.selectedannexure) hasannexure = true;
        }
        if (!hasannexure && ndata[0]?.value) this.selectedannexure = ndata[0]?.value;
        this.readAnnexure(this.selectedannexure, selectedverification._id);
      }
      // console.log(ndata);
      this.annexurelist = ndata;
    },
    readAnnexure(c, v) {
      if(c){
        // if (this.annexuredata[c]) {
        //   this.currentannexuredata = this.annexuredata[c];
        // } else {
          this.loading = true;
          Promise.resolve()
            .then(() => {
              return this.axios.post("/v2/bot/analysis/readannexure/" + this.botdetails._id + "/c/" + c + "/v/" + v);
            })
            .then((dt) => {
              if (dt.data.status === "success") {
                this.annexuredata[c] = dt.data.data;
                this.currentannexuredata = this.annexuredata[c];
              } else throw new Error(dt.data.message || "Error reading annexure");
            })
            .catch((err) => {
              // this.$store.commit("sbError", err.message || err || "Unknown error!");
              this.currentannexuredata = [];
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        // }
      }
    },
    loadBot() {
      this.loading = true;
      return this.axios.post("/v2/bot/project/getmetadata/" + this.botid).then(dt => {
        if (dt.data.status === "success" && dt.data.data.length > 0) {
          let data = dt.data.data[0] || {};
          this.botdetails = data.metadata || {};
          this.uploaddetails = data.uploaddetails || {};
          this.setupList = data.setup || {};
          this.verificationlist = this.setupList.verification || [];
          this.inputparametersvalues = this.botdetails.inputparameter || {};
          this.fileConfigInfo = this.setupList?.fileconfig || {};
          this.rundetails = {};
          for (const i of data.rundetails || []) {
            this.rundetails[i.verification] = i;
          }
          this.generateAnnexurelist();

          // to check if inputparameter are available and if the same is saved
          this.savedParameters = false;
          if ((this.setupList.parameter || []).length > 0) {
            this.hasParameters = true;
            let allparametersaved = true;
            for (let ele of this.setupList.parameter) {
              if (!Object.hasOwnProperty.call((this.botdetails.inputparameter || {}), ele._id)) {
                allparametersaved = false;
              }
            }
            this.savedParameters = allparametersaved;
          } else {
            this.hasParameters = false;
          }

          // set default input parameter values
          let availableids = [];
          for (const k in this.inputparametersvalues) {
            if (Object.hasOwnProperty.call(this.inputparametersvalues, k)) {
              availableids.push(k);
            }
          }
          for (const i of this.setupList.parameter || []) {
            if (availableids.indexOf(i._id) === -1) {
              if (
                Object.hasOwnProperty.call(
                  this.setupList.inputparameterconfig || {},
                  i._id
                )
              )
                this.inputparametersvalues[i._id] = (
                  this.setupList.inputparameterconfig[i._id] || {}
                ).value;
              else this.inputparametersvalues[i._id] = i?.value;
            }
          }

          // to check if files are available and if the same is saved and also flag processing errors
          if ((this.setupList.file || []).length > 0) {
            this.hasFiles = true;
            let allfileprocessed = true;
            let allfilesaved = true;
            let availablefiles = [];
            for (const i of this.botdetails.files || []) {
              availablefiles.push(i.fileid);
            }
            for (let ele of this.setupList.file) {
              let id = ele._id;
              if ((this.uploaddetails[id] || {}).processed !== 1) {
                allfileprocessed = false;
              }
              if ((this.uploaddetails[id] || {}).processed === -1) this.fileprocesserrors[id] = "Error while processing file";
              if (availablefiles.indexOf(id) === -1 || (this.uploaddetails[id].processed || 0) === -1) allfilesaved = false;
            }
            this.savedFiles = allfilesaved;
            this.processedFiles = allfileprocessed;
          } else {
            this.hasFiles = false;
          }
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
      }).finally(() => {
        this.loading = false;
      })
    },
    startRun() {
      this.loading = true;
      this.axios
        .post("/v2/bot/analysis/startrun/" + this.botdetails._id)
        .then((ele) => {
          if (ele.data.status === "success") {
            this.$store.commit("sbSuccess", "Run Started");
            this.loadBot();
          } else throw new Error("Error starting run");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadResult() {
      this.loading = true;
      this.axios.post("/v2/bot/analysis/downloadfile/"+this.botdetails._id).then(dt => {
        if(dt.data.status === "success"){
          let data = dt.data.data[0] || {};
          if(data.stage === 0 || data.stage === 4) this.$store.commit("sbSuccess", "File is being generated please try after sometime");
          else if(data.stage === 2) this.$store.commit("sbSuccess", "There was an error while generating file");
          else if(data.upload) this.$nova.downloadFile(data.upload);
          else return  "no file found to download"
        }else {
          throw new Error (dt.data.message || "Error re-opening project");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    sendReview() {
      this.loading = true;
      this.axios.post("/v2/bot/project/review/" + this.botdetails._id).then((ele) => {
        if (ele.data.status === "success") {
          this.$store.commit("sbSuccess", "Project sent for review");
          this.botdetails.stage = 1;
        } else throw new Error("Error while sending for review");
      })
      .catch((err) => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    open() {
      this.loading = true;
      this.axios.post("/v2/bot/project/open/" + this.botdetails._id).then((ele) => {
        if (ele.data.status === "success") {
          this.$store.commit("sbSuccess", "Project Re-opened");
          this.botdetails.stage = 0;
        } else throw new Error("Error re-opening project");
      })
      .catch((err) => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    close() {
      this.loading = true;
      this.axios.post("/v2/bot/project/close/" + this.botdetails._id).then((ele) => {
        if (ele.data.status === "success") {
          this.$store.commit("sbSuccess", "Project Closed");
          this.botdetails.stage = 3;
        } else throw new Error("Error closing project");
      })
      .catch((err) => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
    },
  },
  watch: {
  }
}
</script>
